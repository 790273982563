<template>
    <div class="rc-estadoSimulacion"
         :class="estadoColor == 'color-1' ? 
                                'border-1': 
                                estadoColor == 'color-2' ? 
                                               'border-2' :
                                               estadoColor == 'color-3' ?
                                                              'border-3' :
                                                              false
                 "
    >
        <span class="estadoSimulacion__titulo">{{tituloData}}</span>
        <span class="estadoSimulacion__subTitulo"
              :class="estadoColor == 'color-1' ? 
                                     'font-1': 
                                     estadoColor == 'color-2' ? 
                                                    'font-2' :
                                                    estadoColor == 'color-3' ?
                                                                   'font-3' :
                                                                   false
                 "
        >{{subData}}</span>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			tituloData: String,
            subData: String,
            estadoColor: String
        },
	})
	export default class EstadosSimulacion extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-estadoSimulacion{
        display: flex;
        flex-direction: column;
        min-width: 180px;
        max-width: 200px;
        text-align: center;
        border-radius: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 16px;
        &.border-1{
            border: 3px solid $rc-sec-hgl1;
        }
        &.border-2{
            border: 3px solid $rc-prim-g1;
        }
        &.border-3{
            border: 3px solid $rc-prim-n1;
        }
        .estadoSimulacion__titulo{
            font-size: 1rem;
            font-weight: 600;
            color: $rc-prim-g2;
        }
        .estadoSimulacion__subTitulo{
            font-size: 1.1rem;
            font-weight: 600;
            &.font-1{
                color: $rc-sec-hgl1;
            }
            &.font-2{
                color: $rc-prim-g1;
            }
            &.font-3{
                color: $rc-prim-n1;
            }
        }
    }
    
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
