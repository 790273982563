<template>
    <div class="rc-simulacionTitulo pa-2">
        <h2>{{ nombreProyecto }}</h2>
		<!-- <h2>Factura a IMPORTADORA EXPORTADDORA Y COMERCIAL OCHO RUEDAS LIMITADA</h2> -->
        <p class="subheading font-weight-light mb-2">ID {{ idProyecto }}</p>
		<!-- <img v-if="!showDebug" width="60%" v-lazy="`${imgProyecto}`" /> -->
		<img width="60%" v-lazy="`${imgProyecto}`" />
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			nombreProyecto: String,
            idProyecto: Number,
            imgProyecto: String,
            showDebug: Boolean
        },
	})
	export default class DebugBar extends Vue {}
</script>

<style lang="scss" scoped>
	.rc-simulacionTitulo{
		h2{
			font-weight: 600;
			color: $rc-prim-g2;
			margin-bottom: 1rem;
		}
		img{
			margin-top: 1rem;
		}
	}
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
