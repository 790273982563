var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rc-tabSimulacion"},[_c('v-container',[_c('v-layout',{attrs:{"wrap":"","row":""}},[_c('v-flex',{attrs:{"xs12":"","md6":"","pa-2":""}},[_c('span',{staticClass:"tabSimulacion__title"},[_vm._v("Detalle de inversión")]),_c('div',{staticClass:"tabSimulacion__content"},[_c('table',{staticClass:"tabSimulacion__table table__col"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Tipo de crédito")]),_c('td',[_vm._v(_vm._s(this.$data.detalleInversion.tipoCredito))])]),_c('tr',[_c('td',[_vm._v("\n                                        Tiempo del préstamo\n                                        "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v("\n                                                    error\n                                                ")])]}}])},[_c('span',[_vm._v("\n                                                Considera desde la fecha de inicio del "),_c('br'),_vm._v("\n                                                proyecto a la fecha estimada de pago. "),_c('br'),_vm._v("\n                                                Si ya pasó la fecha de inicio del proyecto, "),_c('br'),_vm._v("\n                                                este plazo se va reduciendo cada día "),_c('br'),_vm._v("\n                                                (en este caso los intereses comienzan desde "),_c('br'),_vm._v("\n                                                el día de hoy).\n                                            ")])])],1),_c('td',[_vm._v(_vm._s(this.$data.detalleInversion.tiempoPrestamo))])]),_c('tr',[_c('td',[_vm._v("Fecha inicio de proyecto")]),_c('td',[_vm._v(_vm._s(this.$data.detalleInversion.fechaInicio))])]),_c('tr',[_c('td',[_vm._v("Fecha estimada de pago")]),_c('td',[_vm._v(_vm._s(this.$data.detalleInversion.fechaPago))])])])])])]),_c('v-flex',{attrs:{"xs12":"","md6":"","pa-2":""}},[_c('span',{staticClass:"tabSimulacion__title"},[_vm._v("Costo de la inversión")]),_c('div',{staticClass:"tabSimulacion__content"},[_c('table',{staticClass:"tabSimulacion__table table__col"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("\n                                        Derechos por participar\n                                        "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v("\n                                                    error\n                                                ")])]}}])},[_c('span',[_vm._v("\n                                                Es la comisión que cobra "),_c('br'),_vm._v("\n                                                RedCapital por invertir. "),_c('br'),_vm._v("\n                                                Si el proyecto no se financia "),_c('br'),_vm._v("\n                                                se hace una devolución completa.\n                                            ")])])],1),(_vm.costosInversion.derecho == _vm.costosInversion.derechoBruto)?_c('td',[_vm._v("\n                                     "+_vm._s(_vm._f("currency")(_vm.costosInversion.derecho))+" \n                                   ")]):_c('td',{staticStyle:{"width":"40%"}},[_c('span',{staticStyle:{"text-decoration":"line-through","margin-right":"5px"}},[_vm._v("\n                                      "+_vm._s(_vm._f("currency")(_vm.costosInversion.derechoBruto))+"\n                                    ")]),_c('span',{staticStyle:{"color":"#00d1a8"}},[_vm._v("\n                                      "+_vm._s(_vm._f("currency")(_vm.costosInversion.derecho))+"\n                                      "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"custom-info-tooltip",staticStyle:{"color":"rgb(0, 209, 168) !important"}},on),[_vm._v("\n                                            info\n                                          ")])]}}])},[_c('span',[_vm._v("\n                                          Descuento válido hasta "),_c('br'),_vm._v("\n                                          el "+_vm._s(_vm.costosInversion.fechaLimite)+"\n                                        ")])])],1)])]),_c('tr',[_c('td',[_vm._v("Préstamo")]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(_vm.costosInversion.prestamo)))])]),_c('tr',[_c('td',[_vm._v("\n                                        Monto total a invertir (Préstamo + Derechos)\n                                        "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v("\n                                                    error\n                                                ")])]}}])},[_c('span',[_vm._v("\n                                                Corresponde al monto total a "),_c('br'),_vm._v("\n                                                transferir para esta operación. "),_c('br'),_vm._v("\n                                                Sobre este se obtiene la TIR\n                                            ")])])],1),_c('td',[_vm._v(_vm._s(_vm._f("currency")(_vm.costosInversion.monto)))])])])])])]),_c('v-flex',{attrs:{"xs12":"","md6":"","pa-2":""}},[_c('span',{staticClass:"tabSimulacion__title"},[_vm._v("Cuotas")]),_c('div',{staticClass:"tabSimulacion__content"},[_c('table',{staticClass:"tabSimulacion__table table__row"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Mes")]),_c('th',[_vm._v("Cuota")]),_c('th',[_vm._v("Fecha")])])]),_c('tbody',_vm._l((_vm.cuotasInversion),function(cuota){return _c('tr',{key:cuota.mes},[_c('td',[_vm._v(_vm._s(cuota.mes))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(cuota.cuota)))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(cuota.fecha,"DD-MM-YYYY")))])])}),0)])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }