<template>
    <div class="rc-tabSimulacion">
        <v-container>
			<v-layout wrap row>
                <!-- Detalle de inversion -->
				<v-flex xs12 md6 pa-2>
                    <span class="tabSimulacion__title">Detalle de inversión</span>
                    <div class="tabSimulacion__content">
                        <table class="tabSimulacion__table table__col">
                            <tbody>
                                <tr>
                                    <td>Tipo de crédito</td>
                                    <td>{{ this.$data.detalleInversion.tipoCredito }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Tiempo del préstamo
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" style="cursor:pointer;">
                                                    error
                                                </v-icon>
                                            </template>
                                            <span>
                                                Considera desde la fecha de inicio del <br>
                                                proyecto a la fecha estimada de pago. <br>
                                                Si ya pasó la fecha de inicio del proyecto, <br>
                                                este plazo se va reduciendo cada día <br>
                                                (en este caso los intereses comienzan desde <br>
                                                el día de hoy).
                                            </span>
                                        </v-tooltip>
                                    </td>
                                    <td>{{ this.$data.detalleInversion.tiempoPrestamo }}</td>
                                </tr>
                                <tr>
                                    <td>Fecha inicio de proyecto</td>
                                    <td>{{ this.$data.detalleInversion.fechaInicio }}</td>
                                </tr>
                                <tr>
                                    <td>Fecha estimada de pago</td>
                                    <td>{{ this.$data.detalleInversion.fechaPago }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-flex>
                <v-flex xs12 md6 pa-2>
                    <!-- Costo de la inversion -->
                    <span class="tabSimulacion__title">Costo de la inversión</span>
                    <div class="tabSimulacion__content">
                        <table class="tabSimulacion__table table__col">
                            <tbody>
                                <tr>
                                    <td>
                                        Derechos por participar
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" style="cursor:pointer;">
                                                    error
                                                </v-icon>
                                            </template>
                                            <span>
                                                Es la comisión que cobra <br>
                                                RedCapital por invertir. <br>
                                                Si el proyecto no se financia <br>
                                                se hace una devolución completa.
                                            </span>
                                        </v-tooltip>
                                    </td>
                                   <td v-if="costosInversion.derecho == costosInversion.derechoBruto">
                                     {{ costosInversion.derecho | currency }} 
                                   </td>
                                  <td v-else style="width: 40%;">
                                    <span style="text-decoration: line-through; margin-right: 5px;">
                                      {{ costosInversion.derechoBruto | currency }}
                                    </span>
                                    <span style="color: #00d1a8;">
                                      {{ costosInversion.derecho | currency }}
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon class="custom-info-tooltip" v-on="on" style="color: rgb(0, 209, 168) !important;">
                                            info
                                          </v-icon>
                                        </template>
                                        <span>
                                          Descuento válido hasta <br/>
                                          el {{ costosInversion.fechaLimite }}
                                        </span>
                                      </v-tooltip>
                                    </span>

                                  </td>
                                </tr>
                                <tr>
                                    <td>Préstamo</td>
                                    <td>{{ costosInversion.prestamo | currency }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Monto total a invertir (Préstamo + Derechos)
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" style="cursor:pointer;">
                                                    error
                                                </v-icon>
                                            </template>
                                            <span>
                                                Corresponde al monto total a <br>
                                                transferir para esta operación. <br>
                                                Sobre este se obtiene la TIR
                                            </span>
                                        </v-tooltip>
                                    </td>
                                    <td>{{ costosInversion.monto | currency }}</td>
                                </tr>
                             </tbody>
                        </table>
                    </div>
                </v-flex>
                <v-flex xs12 md6 pa-2>
                    <!-- Cuotas -->
                    <span class="tabSimulacion__title">Cuotas</span>
                    <div class="tabSimulacion__content">
                        <table class="tabSimulacion__table table__row">
                            <thead>
                                <tr>
                                    <th>Mes</th>
                                    <th>Cuota</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr :key="cuota.mes" v-for="cuota in cuotasInversion">
                                        <td>{{ cuota.mes }}</td>
                                        <td>{{ cuota.cuota | currency }}</td>
                                        <td>{{ cuota.fecha | moment("DD-MM-YYYY") }}</td>
                                    </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	import moment from "moment";

	@Component({
		props: {
			proyecto: Object,
            cuotas: Array,
            costos: Object
        },
        data() {
            return {
                detalleInversion:{
                    tipoCredito: this.$props.proyecto.credito_tipo.valor,
                    tiempoPrestamo: this.$props.proyecto.credito_tipo_id != 1 ? this.$props.proyecto.cuotas + " Meses" : this.$props.proyecto.timeLimitText(),
                    fechaInicio: this.$props.proyecto.fecha_entrega_dinero ? moment(this.$props.proyecto.fecha_entrega_dinero).format("DD-MM-YYYY") : '-',
                    fechaPago: this.$props.proyecto.fecha_estimada_pago ? moment(this.$props.proyecto.fecha_estimada_pago).format("DD-MM-YYYY") : '-'
                },
                cuotasInversion: this.$props.cuotas.filter(cuota => cuota.mes > 0),
                costosInversion: this.$props.costos
            }
        },
        watch: {
            "costos": {
				handler(val) {
                    this.$data.costosInversion.derecho = val.derecho;
                    this.$data.costosInversion.derechoBruto = val.derechoBruto;
                    this.$data.costosInversion.porcentajeDescuento = val.porcentajeDescuento;
                    this.$data.costosInversion.prestamo = val.prestamo;
                    this.$data.costosInversion.monto = val.monto;
                },
                deep: true
            },
            "cuotas": {
				handler(val) {
                    this.$data.cuotasInversion= this.$props.cuotas.filter(cuota => cuota.mes > 0);
                },
                deep: true
            }
        }
    })
	export default class TabSimulacion extends Vue {}
</script>

<style lang="scss" scoped>
    $color-border: 1px solid $rc-other8;
    .custom-info-tooltip {
      color: $rc-sec-hgl1 !important;
      cursor:pointer;
      margin-left: 0px;
    }
    .rc-tabSimulacion{
        .tabSimulacion__title{
            font-size: 1.2rem;
            font-weight: 600;
            color: $rc-prim-g2;
            margin-bottom: 1rem;
            margin-top: 1rem;
            display: block;
        }
        .tabSimulacion__content{
            border: $color-border;
            border-radius: 10px !important;
            .tabSimulacion__table{
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                &.table__col{
                    tbody > tr:not(:first-child){
                        border-top: $color-border;
                    }
                    tr > td:not(:first-child){
                        border-left: $color-border;
                        font-weight: 600;
                    }
                    tr {
                        height: 2rem;
                        td {
                            padding-left: 20px;
                            i {
                                margin-left: 6px;
                                color: $rc-prim-n1 !important;
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                &.table__row{
                    thead > tr {
                        border-bottom: $color-border;
                    }
                    tbody > tr:not(:first-child){
                        border-top: $color-border;
                    }
                    tr > td:not(:first-child){
                        border-left: $color-border;
                    }
                    tr > th:not(:first-child){
                        border-left: $color-border;
                    }
                    tr {
                        height: 2rem;
                        td {
                            text-align: center;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
   
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
