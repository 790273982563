<template>
    <div class="rc-tabEstadisticas">
        <v-container>
			<v-layout wrap row>
                <!-- Detalle de inversion -->
				<v-flex xs12 md6 pa-2>
                    <span class="tabEstadisticas__title">Información del solicitante</span>
                    <div class="tabEstadisticas__content">
                        <table class="tabEstadisticas__table table__col">
                            <tbody>
                                <tr>
                                    <td>Años de operación</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ proyecto.empresa.anio != null ? proyecto.empresa.anio.anio_meses : '0 Años' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total facturas cedidas</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[0][0].TotalFacturaEmpresa }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Facturas cedidas pagadas</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[0][0].TotalFacturaPagadasEmpresa }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Facturas cedidas vigentes</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[0][0].TotalFacturaVigenteEmpresa }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Facturas cedidas en mora</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[0][0].Total_Facturas_Mora }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tiempo promedio de mora</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[0][0].Total_Promedio_Mora_Dias == 1 ? estatico[0][0].Total_Promedio_Mora_Dias + " día" : estatico[0][0].Total_Promedio_Mora_Dias + " días" }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-flex>
                <!-- Información Pagador -->
                <v-flex xs12 md6 pa-2>
                    <span class="tabEstadisticas__title">Información del pagador</span>
                    <div class="tabEstadisticas__content">
                        <table class="tabEstadisticas__table table__col">
                            <tbody>
                                <tr>
                                    <td>Total facturas recibidas</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[1].get_cant_factura_count }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Facturas recibidas pagadas</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[1].facturas_pagadas_deudor }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Facturas recibidas vigentes</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[1].facturas_vigentes_deudor }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Facturas recibidas con mora</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[1].facturas_mora_deudor }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tiempo promedio de mora</td>
                                    <td v-if="this.$props.estatico != null">
                                        {{ estatico[1].promedio_mora_deudor == 1 ? estatico[1].promedio_mora_deudor + " día" : estatico[1].promedio_mora_deudor + " días" }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			proyecto: Object,
            facturas: Object,
            estatico: Array
        },
    })
	export default class TabEstadisticas extends Vue {}
</script>

<style lang="scss" scoped>
    $color-border: 1px solid $rc-other8;
    .rc-tabEstadisticas{
        .tabEstadisticas__title{
            font-size: 1.2rem;
            font-weight: 600;
            color: $rc-prim-g2;
            margin-bottom: 1rem;
            margin-top: 1rem;
            display: block;
        }
        .tabEstadisticas__content{
            border: $color-border;
            border-radius: 10px !important;
            .tabEstadisticas__table{
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                &.table__col{
                    tbody > tr:not(:first-child){
                        border-top: $color-border;
                    }
                    tr > td:not(:first-child){
                        border-left: $color-border;
                        font-weight: 600;
                    }
                    tr {
                        height: 2rem;
                        td {
                            padding-left: 20px;
                            i {
                                margin-left: 6px;
                                color: $rc-prim-n1 !important;
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
   
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
