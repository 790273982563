<template>
    <div class="rc-littleBox"
         :class="tipo == 'body' ? 'littleBox__content' : false"
    >
        <div v-if="imgData" class="littleBox__img">
            <img :src="imgData" :alt="tituloData">
        </div>
        <div v-if="!loadData" class="littleBox__txt">
            <span class="txt__title">{{tituloData}}</span>
            <span class="txt__subTitle">{{subData}}</span>
        </div>
        <div v-else class="littleBox__txt">
            <span class="txt__title">
                Simulando 
                <v-icon class="spin" style="color: #3d3d3d" dark>cached</v-icon>
            </span>
            <span class="txt__subTitle">{{subData}}</span>
        </div>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			tituloData: String,
            subData: String,
            imgData: String,
            tipo: String,
            loadData: {
                default: false,
                type: Boolean
            }
        },
	})
	export default class DebugBar extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-littleBox{
        text-align: center;
        background-color: $rc-escala5;
        filter: $rc-dropshadow;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.littleBox__content{
            width: 240px !important;
            .littleBox__txt{
                text-align: center !important;
                padding-top: 9px;
                padding-bottom: 9px;
                .txt__title{
                    font-size: 1.5rem;
                }
                .txt__subTitle{
                    font-size: 1rem;
                    color: $rc-prim-g2;
                }
            }
        }
        .littleBox__img{
            img{
                /* width: 40px; */
                height: 40px;
                margin-right: 10px;
            }
        }
        .littleBox__txt{
            text-align: left;
            span{
                display: block;
            }
            .txt__title{
                font-size: 1rem;
                font-weight: 600;
                color: $rc-prim-g2;
            }
            .txt__subTitle{
                font-size: 0.8rem;
                font-weight: 500;
                color: $rc-prim-g1;
            }
        }

    }   
    
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
