var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rc-estadoSimulacion",class:_vm.estadoColor == 'color-1' ? 
                            'border-1': 
                            _vm.estadoColor == 'color-2' ? 
                                           'border-2' :
                                           _vm.estadoColor == 'color-3' ?
                                                          'border-3' :
                                                          false},[_c('span',{staticClass:"estadoSimulacion__titulo"},[_vm._v(_vm._s(_vm.tituloData))]),_c('span',{staticClass:"estadoSimulacion__subTitulo",class:_vm.estadoColor == 'color-1' ? 
                                 'font-1': 
                                 _vm.estadoColor == 'color-2' ? 
                                                'font-2' :
                                                _vm.estadoColor == 'color-3' ?
                                                               'font-3' :
                                                               false},[_vm._v(_vm._s(_vm.subData))])])}
var staticRenderFns = []

export { render, staticRenderFns }