<template>
    <div class="rc-tabRespaldos">
        <v-container>
			<v-layout wrap row>
                <v-flex xs12 pa-2>
                    <span class="tabRespaldos__title">Respaldos</span>
                </v-flex>
                <!-- Respaldos -->
				<v-flex xs12 md6 pa-2>
                    <div class="tabRespaldos__content">
                        <table 
                            v-if="this.$props.proyecto.credito_tipo_id == 1"
                            class="tabRespaldos__table table__col"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(1) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(1) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Pagaré a nombre de Inversionistas
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(6) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(6) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Factura electrónica cedida
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(8) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(8) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Cheque de respaldo
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table 
                            v-if="this.$props.proyecto.credito_tipo_id == 2  || this.$props.proyecto.credito_tipo_id == 3 || this.$props.proyecto.credito_tipo_id == 4"
                            class="tabRespaldos__table table__col"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(1) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(1) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Pagaré a nombre de Inversionistas
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(2) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(2) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Carta de garantía a RedCapital en favor de los inversionistas
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(3) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(3) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Depósito
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(11) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(11) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Propiedad a RedCapital en favor Inversionistas
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table 
                            v-if="this.$props.proyecto.credito_tipo_id == 6"
                            class="tabRespaldos__table table__col"
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(1) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(1) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Pagaré a nombre de Inversionistas
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(2) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(2) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Carta de garantía a RedCapital en favor de los inversionistas
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(3) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(3) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Depósito
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(11) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(11) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Propiedad a RedCapital en favor de Inversionistas
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(14) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(14) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Hipoteca a RedCapital en favor del inversionista
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <v-icon 
                                                class="material-icons"
                                                :class="this.$props.proyecto.garantia.map(el => el.id).includes(14) ? 'ico__ok' : 'ico__err'"
                                            >
                                                {{ this.$props.proyecto.garantia.map(el => el.id).includes(14) ? "check_circle" : "cancel" }}
                                            </v-icon>
                                            Poliza a RedCapital en favor del inversionista
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-flex>
                <!-- Tabla -->
                <v-flex xs12 md6 pa-2>
                    <div style="overflow: auto;">
                        <div class="tabRespaldos__content" 
                             v-if="this.$props.proyecto.credito_tipo_id == 1"
                             style="width: max-content;"
                        >
                            <table class="tabRespaldos__table table__row">
                                <thead>
                                    <tr>
                                        <th :key="txt.text" v-for="txt in thead">{{txt.text}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="item.folio" v-for="item in this.$props.proyecto.proyecto_factura.facturas">
                                        <td class="text-xs-left" :title="item.deudor.nombre">
                                            {{ item.deudor.nombre }}
                                        </td>
                                        <td class="text-xs-left">
                                            {{ item.deudor.rut }}
                                        </td>
                                        <td class="text-xs-left">
                                            {{ item.folio }}
                                        </td>
                                        <td class="text-xs-left">
                                            {{ item.fecha_vencimiento | moment("DD-MM-YYYY") }}
                                        </td>
                                        <td class="text-xs-left">
                                            {{ parseInt(item.monto) | currency }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
    import moment from "moment";
	
	@Component({
		props: {
			proyecto: Object,
        },
        data(){
            return {
                thead: [{
                        text: "Nombre Deudor",
                        value: "nombre"
                    },{
                        text: "Rut Deudor",
                        value: "rut"
                    },{
                        text: "Folio",
                        value: "folio"
                    },{
                        text: "Vencimiento",
                        value: "fecha"
                    },{
                        text: "Monto",
                        value: "monto"
                    }
                ],
            }
        }
    })
	export default class TabRespaldos extends Vue {}
</script>

<style lang="scss" scoped>
    $color-border: 1px solid $rc-other8;
    .rc-tabRespaldos{
        .tabRespaldos__title{
            font-size: 1.2rem;
            font-weight: 600;
            color: $rc-prim-g2;
            margin-bottom: 1rem;
            margin-top: 1rem;
            display: block;
        }
        .tabRespaldos__content{
            border: $color-border;
            border-radius: 10px !important;
            .tabRespaldos__table{
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                &.table__col{
                    tbody > tr:not(:first-child){
                        border-top: $color-border;
                    }
                    tr {
                        height: 2rem;
                        td {
                            padding-left: 20px;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            span{
                                height: 2rem;
                                display: flex;
                                align-items: center;
                                .ico__ok{
                                    color: $rc-sec-hgl1;
                                    margin-right: 6px;
                                }
                                .ico__err{
                                    color: $rc-sec-error3;
                                    margin-right: 6px;
                                }
                            }
                            /* i {
                                margin-left: 6px;
                                color: $rc-prim-n1 !important;
                                transform: rotate(180deg);
                            } */
                        }
                    }
                }
                &.table__row{
                    thead > tr {
                        border-bottom: $color-border;
                        background-color: $rc-other5;
                    }
                    tbody > tr:not(:first-child){
                        border-top: $color-border;
                    }
                    tr > td:not(:first-child){
                        border-left: $color-border;
                    }
                    tr > th:not(:first-child){
                        border-left: $color-border;
                    }
                    tr {
                        height: 2rem;
                        td {
                            text-align: left;
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                        th {
                            font-weight: 600;
                            text-align: left;
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }
   
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
