<template>
    <div class="text-xs-left rc-debugBar">
        <p class="mb-2 title font-weight-bold text-xs-center">DEBUG BAR</p>
        <p class="mb-0">Club state: <b>{{ clubState }}</b></p>
        <p class="mb-0">Partner state: <b>{{ partnerState }}</b></p>
        <p class="mb-0">Montos para comparacion y verificacion:</p>
        <p class="mb-0">Monto a invertir: <b>{{montoInvertir}}</b></p>
        <p class="mb-0">Monto a invertir: <b>{{ultimoMonto}}</b></p>
        <p class="mb-0">Monto derechos: <b>{{montoDerechos}}</b></p>
        <p class="mb-0">Monto disponible otros inversionistas:<b>{{ montoDisponible }}</b></p>
        <p class="mb-0">Monto minimo a invertir: <b>{{ minimoInvertir }}</b></p>
        <p class="mb-0">Monto maximo a invertir: <b>{{ maximoInvertir }}</b></p>
        <p class="mb-0">Mi Monto disponible: <b>{{ miMontoDisponible }}</b></p>
        <p class="mb-0">Es la ultima inversion: <b>{{ ultimaInversion }}</b></p>
        <p class="mb-0">Monto a invertir (Con logica): <b>{{ montoInvertirLogica }}</b></p>
        <p class="mb-0">Monto prestamo: <b>{{ montoPrestamo }}</b></p>
        <div v-if="temporalInvest != null">
            Temporales:
            <div>
                <span style="padding-left: 16px;" :key="tmp.id" v-for="tmp in temporalInvest">
                    - <b>{{ tmp.monto | currency }}</b>
                </span>
            </div>
            <p class="mb-0" v-if="temporalInvest != null && temporalInvest.length > 0">
                Suma de mis temporales:
                <b>{{temporalInvest.reduce((last, tmp) => last + tmp.monto,0) | currency}}</b>
            </p>
        </div>
        <p class="mb-0">
            Monto inversión inicial:
            <b>{{initialInvest != null ? $options.filters.currency(initialInvest.monto) : "No creada"}}</b>
        </p>
        <p class="mb-0">Puede reservar: <b>{{ puedeReservar }}</b></p>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			clubState : Number,
            partnerState : Number,
            montoInvertir: String,
            ultimoMonto: String,
            montoDerechos: String,
            montoDisponible: String,
            minimoInvertir: String,
            maximoInvertir: String,
            miMontoDisponible: String,
            ultimaInversion: String,
            montoInvertirLogica: String,
            montoPrestamo: String,
            temporalInvest: Array,
            initialInvest: Object,
            puedeReservar: String
        },
	})
	export default class DebugBar extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-debugBar{
        color:white; 
        background-color: #951836; 
        margin: 10px; 
        padding: 20px; 
        border-radius: 10px
    }
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
