<template>
    <div class="rc-tabDetalle">
        <v-container>
			<v-layout wrap row>
                <v-flex xs12 pa-2>
                    <div class="tabDetalle__content" v-if="this.$props.proyecto.resumen != '.'">
                        <div v-html="this.$props.proyecto.resumen"></div>
                    </div>
                    <div class="tabDetalle__content" v-if="this.$props.proyecto.resumen == '.'">
                        <div v-html="this.$props.proyecto.motivo"></div>
                    </div>
                    <div v-if="this.$props.proyecto.proyecto_archivo != null">
                        <v-btn 
                            v-if="this.$props.proyecto.proyecto_archivo != null" 
                            class="btn-panel" color="primary"
                            style="text-transform: none"
                            @click="descargar(proyecto.proyecto_archivo.id)"
                        >
                            Tasación
                        </v-btn> 
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	import { Configuration } from "@/configuration";

	@Component({
		props: {
			proyecto: Object,
        },
        methods: {
            descargar(id_archivo){
                window.open(`${Configuration.api.base}/descargar_archivo/${id_archivo}`);
            },
        }
    })
	export default class TabDetalle extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-tabDetalle{
        .tabDetalle__content{
            color: $rc-prim-g2;
        }
    }
   
    @each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			    
			}@else if $breakpoint == "small"{
                
			}
		}
	}
</style>
